export const theme = {
  colors: {
    greenTitle: '#21A038',
    greenBg: '#27C498',
    greenLightBg: '#CEFFE8',
    greenLightText: '#008D4B',
    grayTitle: '#999999',
    graySubtitle: '#868686',
    grayBorderButton: '#E4E5E7',
    grayBorderBox: '#D0D0D0',
    grayBgButton: '#F6F6F6',
    grayBgBox: '#080808',
    grayText: '#EBEBEB',
    grayHint: '#AEB1B0',
    grayDark: '#85858F',
    grayBg: '#f4f4f4',
    whiteBg: '#ffffff',
    grayPreviewSubtitle: 'rgba(80, 57, 57, 0.6)',
    dateArrowIcon: '#BEBEBE',
    blackLabel: '#323232',
    blackSidebar: '#212121',
    blackPlaygroundTitle: '#020814',
    blackPlaygroundLinkTitle: '#171725',
    blackPlaygroundLinkText: 'rgba(23, 23, 37, 0.6)',
    orange: '#FF7866',
    orangeLightBg: '#FFEFE0',
    orangeLightText: '#FF881B',
    questionPurpleBg: '#572FC8',
    questionDarkPurpleBg: '#54008C',
    questionRedBg: '#FF335F',
    previewSubtitle: 'rgba(0, 0, 0, 0.6)',
  },
};
